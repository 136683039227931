<template>
  <el-card>
    <el-row>
      <el-col :span="20">
        <el-form :model="entity" label-width="50px">
          <el-form-item label="封面">
            <el-upload
                class="avatar-uploader"
                action="http://8.137.119.117:9090/files/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
            >
              <img v-if="entity.img" :src="entity.img" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="entity.title" autocomplete="off" style="width: 50%"></el-input>
          </el-form-item>
          <el-form-item label="简介">
            <el-input v-model="entity.description" autocomplete="off" style="width: 50%"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <div id="div1"></div>
          </el-form-item>
        </el-form>
        <div style="padding-left: 50px">
          <el-button type="primary" @click="save">保存</el-button>
          <el-button type="warning" @click="$router.push('/article')">取消</el-button>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import E from 'wangeditor'
import request from "@/utils/request";
let url = "/article"
let editor;

export default {
  name: 'ArticleDetail',
  components: {

  },
  data() {
    return {
      loading: true,
      entity: {},
    }
  },
  created() {
    this.$nextTick(() => {
      editor = new E('#div1')
      // 配置 server 接口地址
      editor.config.uploadImgServer = 'http://8.137.119.117:9090/files/editor/upload'
      editor.config.uploadFileName = "file"  // 设置上传参数名称
      editor.create()

      let id = location.search.split("=")[1]
      if(id) {
        this.entity.id = id
        request.get(url + "/" + id).then(res => {
          this.entity = res.data
          editor.txt.html(res.data.content)
        })
      }
    })
  },
  methods: {
    handleAvatarSuccess(res) {
      this.entity.img = res.data
    },
    save() {
      this.entity.content = editor.txt.html()  // 获取 编辑器里面的值，然后赋予到实体当中
      if(!this.entity.title || !this.entity.content) {
        this.$message({
          type: "error",
          message: "请填写完整内容"
        })
        return
      }
      let user = JSON.parse(sessionStorage.getItem("user"));
      this.entity.author = user.nickName

      if (this.entity.id) {  // 更新
        request.put(url, this.entity).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "更新成功"
            })
            this.$router.push("/article")
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() // 刷新表格的数据
          this.dialogVisible = false  // 关闭弹窗
        })
      }  else {  // 新增
        request.post(url, this.entity).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "新增成功"
            })
            this.$router.push("/article")
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }

          this.load() // 刷新表格的数据
          this.dialogVisible = false  // 关闭弹窗
        })
      }

    },
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
